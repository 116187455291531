import { create } from 'zustand';
import { onValue, ref } from 'firebase/database';
import * as F from '@/lib/firebase';
import { Vendor } from '@/lib/definitions';

interface VendorState {
  vendor: Vendor | null;
  vendorId: string | null;
  loading: boolean;
  subscriberCount: number;
  unsubscribe: (() => void) | null;
  fetchVendor: (companyId: string) => () => void;
  releaseVendor: () => void;
}

export const useVendorStore = create<VendorState>((set, get) => ({
  vendor: null,
  vendorId: null,
  loading: true,
  subscriberCount: 0,
  unsubscribe: null,

  /**
   * Fetch vendor details
   * @param referenceId Can be either userId or vendorId
   * @returns 
   */
  fetchVendor: (referenceId) => {
    const isVendor = referenceId.startsWith('vendor__');
    if (!isVendor) {
      set({ loading: false });
      return () => { }; // Return a no-op cleanup function if not a vendor
    }

    const vendorId = referenceId.split('__')[1];

    if (get().vendorId === vendorId && get().subscriberCount > 0) {
      set((state) => ({ subscriberCount: state.subscriberCount + 1, loading: false }));
      return () => get().releaseVendor(); // Return releaseVendor for cleanup
    }

    get().unsubscribe?.();

    set({ vendorId, loading: true, subscriberCount: 1 });

    const vendorRef = ref(F.db, `vendors/${vendorId}`);
    const unsubscribe = onValue(vendorRef, (snapshot) => {
      const vendor = snapshot.val();
      set({ vendor, loading: false });
    }, (error) => {
      console.error(error);
      set({ loading: false });
    });

    set({ unsubscribe });

    return () => get().releaseVendor(); // Return releaseVendor for cleanup
  },

  releaseVendor: () => {
    set((state) => {
      const newCount = state.subscriberCount - 1;
      if (newCount <= 0) {
        state.unsubscribe?.();
        return { subscriberCount: 0, unsubscribe: null };
      }
      return { subscriberCount: newCount };
    });
  },
}));
