import { Outlet, RouteObject } from 'react-router-dom';
import { lazyImport } from '@/lib/utils';
import { AuthLayout } from '@/features/auth/components/auth-layout';
import { Toaster } from '@/providers/toaster';

const { ForgotPassword } = lazyImport(() => import('@/features/auth/pages'), 'ForgotPassword');
const { Register } = lazyImport(() => import('@/features/auth/pages'), 'Register');
const { Login } = lazyImport(() => import('@/features/auth/pages'), 'Login');
const { VerifyEmail } = lazyImport(() => import('@/features/auth/pages'), 'VerifyEmail');
const { FirebaseAction } = lazyImport(() => import('@/features/auth/pages'), 'FirebaseAction');
const { VendorUsersAuth } = lazyImport(() => import('@/features/auth/pages'), 'VendorUsersAuth');

export const authRoutes: RouteObject[] = [
  {
    element: (
      <AuthLayout>
        <Outlet />
        <Toaster />
      </AuthLayout>
    ),
    children: [
      { path: '/login', element: <Login /> },
      { path: '/signup', element: <Register /> },
      { path: '/forgot-password', element: <ForgotPassword /> },
      { path: '/verify', element: <VerifyEmail /> },
      { path: '/firebase-action', element: <FirebaseAction /> },
      {
        path: '/authentication',
        element: <VendorUsersAuth />,
      },
    ],
  },
];
