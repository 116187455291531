import { Permission } from '@/data/permission';
import { useUser } from '@/providers/user';
import { Fragment, useMemo } from 'react';
import { useRoles } from '../hooks/use-roles';
import { useCompany } from '../providers/company';


type ACLProps = {
  permission: Permission | Permission[];
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

export const ACL = ({
  permission,
  children,
  fallback
}: ACLProps) => {
  const { user } = useUser('ACL');
  const { activeCompany: company } = useCompany('ACL');
  const roles = useRoles('ACL');

  const requiredPermissions = useMemo(() => {
    return Array.isArray(permission) ? permission : [permission];
  }, [permission]);

  const permissions = useMemo(() => {
    if (!company.members?.[user.uid]) {
      return [];
    }

    // Get the user's role(s), treating it as an array if it's a single string
    const userRoles = company.members[user.uid].role;
    const roleIds = Array.isArray(userRoles) ? userRoles : [userRoles];

    // Aggregate permissions from all roles, ensuring type safety
    const combinedPermissions = roleIds.reduce<string[]>((acc, roleId) => {
      const role = roles.getRole(roleId);
      if (role && role.permissions) {
        acc.push(...role.permissions);
      }
      return acc;
    }, []);
    

    // Remove duplicate permissions
    return Array.from(new Set(combinedPermissions));
  }, [company.members, user.uid, roles]);

  const hasPermission = useMemo(() => {
    return requiredPermissions.some((p) => {
      const wildcardPermission = p.split(':')[0] + ':*';
      return permissions.includes(p) || permissions.includes(wildcardPermission);
    });
  }, [permissions, requiredPermissions]);

  return (
    <Fragment>
      {hasPermission ? children : fallback ?? null}
    </Fragment>
  );
};
