import { Button } from '@/components/ui';
import { useUser } from '@/providers/user';
import { ChevronDownIcon, Cross1Icon, HamburgerMenuIcon } from '@radix-ui/react-icons';
import { useUI } from '@/providers/ui';
import { AccountPopover } from '@/features/company/components/views/account';
import { Avatar } from '@/features/company/components';

export const VendorHeader: React.FC = () => {
  const { user } = useUser('AppHeader');
  const { isSideBarOpen, toggleSideBar } = useUI('VendorAppHeader', (state) => state);

  return (
    <div className='flex w-full px-4 py-1'>
      {/* <p className="hidden md:block text-sky-950 xl:mx-12 mt-2 ">Overview</p> */}
      <div className='flex items-center gap-4'>
        <Button
          size='icon'
          variant='ghost'
          className='md:hidden text-foreground cursor-pointer'
          onClick={toggleSideBar}
        >
          {!isSideBarOpen ? (
            <HamburgerMenuIcon width={24} height={24} />
          ) : (
            <Cross1Icon width={24} height={24} />
          )}
        </Button>
      </div>
      <div className='flex gap-8 items-center ml-auto'>
        <AccountPopover>
          <div className='flex items-center gap-2 cursor-pointer'>
            <div className='hidden md:block'>
              <div className='text-base leading-4 font-medium'>{user?.fullName}</div>
            </div>
            <Avatar name={user.fullName} />

            <ChevronDownIcon className='hidden md:block' />
          </div>
        </AccountPopover>
      </div>
    </div>
  );
};

export const MinHeader: React.FC = () => {
  return <></>;
};
