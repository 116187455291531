import {
  AuthProvider,
  DatabaseProvider,
  FirebaseAppProvider,
  FirestoreProvider,
  FunctionsProvider,
  StorageProvider,
} from 'reactfire';
import * as F from '@/lib/firebase';

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <FirebaseAppProvider firebaseApp={F.app}>
      <AuthProvider sdk={F.auth}>
        <DatabaseProvider sdk={F.db}>
          <FirestoreProvider sdk={F.firestore}>
            <StorageProvider sdk={F.storage}>
              <FunctionsProvider sdk={F.functions}>{children}</FunctionsProvider>
            </StorageProvider>
          </FirestoreProvider>
        </DatabaseProvider>
      </AuthProvider>
    </FirebaseAppProvider>
  );
};
