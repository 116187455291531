import { cn } from '@/lib/utils';
import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const LabelWrapper: React.FC<React.PropsWithChildren & { label: string; id: string }> = ({
  label,
  children,
  id,
}) => {
  if (!label) {
    return <>{children}</>;
  }
  return (
    <div className='flex gap-2'>
      {children}
      <label htmlFor={id} className='text-sm font-normal text-muted-foreground h'>
        {label}
      </label>
    </div>
  );
};

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, id, ...props }, ref) => {
    const genId = React.useId();
    return (
      <LabelWrapper label={label!} id={id ?? genId}>
        <input id={id ?? genId} type='checkbox' ref={ref} {...props} />
      </LabelWrapper>
    );
  },
);

Checkbox.displayName = 'Checkbox';

const Switch = React.forwardRef<HTMLInputElement, CheckboxProps>(({ className, ...props }, ref) => {
  return <Checkbox className={cn('switch', className)} ref={ref} {...props} />;
});

Switch.displayName = 'Switch';

export { Checkbox, Switch };

const ChkRoot = React.forwardRef<HTMLButtonElement, CheckboxPrimitive.CheckboxProps>(
  ({ checked, onCheckedChange, ...props }, ref) => {
    return (
      <CheckboxPrimitive.Root
        ref={ref}
        checked={checked}
        onCheckedChange={onCheckedChange}
        {...props}
        className={cn(
          'border border-stroke hover:bg-muted flex !h-6 !w-6 appearance-none items-center justify-center rounded-md bg-background outline-none focus:ring-2 focus:ring-primary/10 data-[disabled=]:bg-stroke/20 data-[disabled=]:opacity-70',
          props.className,
        )}
      />
    );
  },
);

ChkRoot.displayName = 'Checkbox';

const ChkIndicator = (props: CheckboxPrimitive.CheckboxIndicatorProps) => (
  <CheckboxPrimitive.CheckboxIndicator
    {...props}
    className={cn('text-muted-foreground', props.className)}
  >
    <CheckIcon width={16} />
  </CheckboxPrimitive.CheckboxIndicator>
);


export type ChkState = CheckboxPrimitive.CheckedState;

export const ChkBox = {
  Root: ChkRoot,
  Indicator: ChkIndicator,
};
