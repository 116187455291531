const cateogoriesObject = {
  'hr-contracts': 'HR Contracts',
  'commercial-contracts': 'Commercial Contracts',
  'real-estate': 'Real Estate',
  'ip-and-trademarks': 'IP and Trademarks',
  'board-matters': 'Board Matters',
  'funding': 'Funding',
};

type CategoryValues = keyof typeof cateogoriesObject;

const categories = Object.entries(cateogoriesObject).map(([value, label]) => ({
  label,
  value,
}));

export default categories;
export { cateogoriesObject };
export type { CategoryValues };