import { forwardRef } from 'react';
import { Spinner } from './icons';
import { cn } from '@/lib/utils';

export const Fallback = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>((props, ref) => (
  <div ref={ref} {...props} className={cn('h-screen grid place-items-center', props.className)}>
    <Spinner />
  </div>
));

Fallback.displayName = 'Fallback';
