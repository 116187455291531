import { cn } from '@/lib/utils';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { forwardRef } from 'react';

const Root = (props: DropdownMenu.DropdownMenuProps) => <DropdownMenu.Root {...props} />;

const Trigger = forwardRef<HTMLButtonElement, DropdownMenu.DropdownMenuTriggerProps>(
  (props, ref) => <DropdownMenu.Trigger ref={ref} {...props} />,
);

Trigger.displayName = 'MenuTrigger';

const Content = ({ className, children, ...props }: DropdownMenu.DropdownMenuContentProps) => (
  <DropdownMenu.Portal>
    <DropdownMenu.Content
      alignOffset={8}
      sideOffset={8}
      collisionPadding={8}
      {...props}
      className={cn(
        'p-2 bg-background rounded-xl shadow-sm border border-stroke/10 focus:outline-none z-10',
        className,
      )}
    >
      {children}
    </DropdownMenu.Content>
  </DropdownMenu.Portal>
);

const Item = forwardRef<HTMLDivElement, DropdownMenu.DropdownMenuItemProps>((props, ref) => (
  <DropdownMenu.Item
    ref={ref}
    {...props}
    className={cn(
      'px-5 py-2 rounded-lg text-sm text-muted-foreground hover:bg-accent/20 hover:text-foreground cursor-pointer outline-none focus:outline-none',
      'flex gap-3 items-center',
      props.className,
    )}
  />
));

Item.displayName = 'MenuItem';

const Sub = (props: DropdownMenu.DropdownMenuSubProps) => <DropdownMenu.Sub {...props} />;

const SubTrigger = (props: DropdownMenu.DropdownMenuSubTriggerProps) => (
  <DropdownMenu.SubTrigger {...props} />
);

const SubContent = (props: DropdownMenu.DropdownMenuSubContentProps) => (
  <DropdownMenu.SubContent {...props} />
);

export const Menu = {
  Root,
  Trigger,
  Content,
  Item,
  Sub,
  SubTrigger,
  SubContent,
};
