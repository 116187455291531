import { useCompany } from '@/features/company/providers/company';
import { Notification } from '@/lib/definitions';
import { useUser } from '@/providers/user';
import { ref, increment, update } from 'firebase/database';
import { useCallback } from 'react';
import { useDatabase, useDatabaseObjectData } from 'reactfire';

export const useNotifications = (consumerName: string) => {
  const { user } = useUser(consumerName);
  const { activeCompany: company } = useCompany(consumerName);

  const db = useDatabase();

  const notificationRef = ref(db, `notifications/${company?.id}/${user?.uid}`);
  const { data, ...response } = useDatabaseObjectData<Notification>(notificationRef);

  const readItem = useCallback(async (notificationId: string) => {
    // Atomic update to mark the notification as read
    const updates = {} as Record<string, any>;
    updates[`notifications/${company?.id}/${user?.uid}/notifications/${notificationId}/read`] = true;
    updates[`notifications/${company?.id}/${user?.uid}/unreadCount`] = increment(-1);

    await update(ref(db), updates);
  }, []);

  const cleanUp = useCallback(async () => {
    if (!data?.notifications || Object.keys(data.notifications).length === 0) return;

    const updates = {} as Record<string, any>;
    Object.values(data.notifications).forEach((notification) => {
      if (notification.read) {
        updates[`notifications/${company?.id}/${user?.uid}/notifications/${notification.id}`] = null;
      }
    });

    await update(ref(db), updates);
  }, [data?.notifications]);

  return {
    ...response,
    data: Object.values(data?.notifications ?? {}),
    unreadCount: data?.unreadCount ?? 0,
    readItem,
    cleanUp
  };
};