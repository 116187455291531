import { Status } from '@/lib/definitions';

export const defaultStatuses = {
  draft: {
    label: 'draft',
    color: '#e8ecee',
  },
  pending: {
    label: 'pending',
    color: '#FFB800',
  },
  signed: {
    label: 'signed',
    color: '#a0c3ff',
  },
  declined: {
    label: 'declined',
    color: '#ff6b78',
  },
  completed: {
    label: 'completed',
    color: '#64C2A6',
  }
} as const;

export const defaultStatusesArray = Object.entries(defaultStatuses).map(([id, status]) => ({
  id,
  ...status,
})) as Status[];

