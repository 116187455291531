import { create } from 'zustand';

interface Upload {
  id: string;
  name: string;
  file: File;
  parentId: string;
  accessBy: string[];
  status: 'queued' | 'uploading' | 'completed' | 'failed';
  progress: number;
  error?: string;
}

type UploadParams = Omit<Upload, 'id' | 'status' | 'progress' | 'error'>;

interface UploadState {
  uploads: Upload[];
  currentUploadId: string | null;
  getUpload: (id: string) => Upload | undefined;
  addUpload: (params: UploadParams) => void;
  startUpload: (id: string) => void;
  updateUpload: (id: string, progress: number) => void;
  completeUpload: (id: string) => void;
  removeUpload: (id: string) => void;
  clearCompletedUploads: () => void;
}

export const useUpload = create<UploadState>()((set, get) => ({
  uploads: [],
  currentUploadId: null,
  getUpload: (id) => {
    return get().uploads.find((upload) => upload.id === id);
  },
  addUpload: async ({ file, name, parentId, accessBy }) => {
    const id = Math.random().toString(36).substr(2, 9);
    set((state) => ({
      uploads: [...state.uploads, { id, name, file, parentId, accessBy, status: 'queued', progress: 0 }],
      currentUploadId: state.currentUploadId == null ? id : state.currentUploadId,
    }));
  },
  startUpload: async (id) => {
    set((state) => ({
      uploads: state.uploads.map((upload) => {
        if (upload.id === id) {
          return { ...upload, status: 'uploading' };
        }
        return upload;
      }),
    }));
  },
  updateUpload: async (id, progress) => {
    set((state) => ({
      uploads: state.uploads.map((upload) => {
        if (upload.id === id) {
          return { ...upload, progress };
        }
        return upload;
      }),
    }));
  },
  completeUpload: async (id) => {
    set((state) => ({
      uploads: state.uploads.map((upload) => {
        if (upload.id === id) {
          return { ...upload, status: 'completed' };
        }
        return upload;
      }),
      // find the next upload that is queued
      currentUploadId: state.uploads.find((upload) => upload.status === 'queued')?.id ?? null,
    }));
  },
  removeUpload: async (id) => {
    set((state) => ({
      uploads: state.uploads.filter((upload) => upload.id !== id),
    }));
  },
  clearCompletedUploads: async () => {
    set((state) => ({
      uploads: state.uploads.filter((upload) => upload.status !== 'completed'),
    }));
  },
}));
