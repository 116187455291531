import { defaultStatuses, defaultStatusesArray } from '@/data/statuses';
import { useCallback, useMemo } from 'react';
import { useCompany } from '../providers/company';

export const useStatuses = (consumerName: string) => {
  const { activeCompany: company } = useCompany(consumerName);

  const statuses = useMemo(() => {
    const statuses = Array.from(defaultStatusesArray);
    const visited = new Set<string>(statuses.map((status) => status.id));

    const companyStatuses = Object.values(company?.statuses ?? {});
    companyStatuses.forEach((status) => {
      if (!visited.has(status.id)) {
        statuses.push(status);
        visited.add(status.id);
      }
    });

    return statuses;
  }, [company]);
  
  const getStatus = useCallback((id?: string) => {
    if (!id) return defaultStatuses.draft;

    if (id in defaultStatuses) {
      return defaultStatuses[id as keyof typeof defaultStatuses];
    }
    if (company.statuses && id in company.statuses) {
      return company.statuses[id];
    }
    return defaultStatuses.draft;
  }, [company]);

  return { statuses, getStatus };
};
