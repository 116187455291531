export default [
  {
    label: 'Botswana',
    value: 'Botswana',
  },
  {
    label: 'Kenya',
    value: 'Kenya',
  },
  {
    label: 'Nigeria',
    value: 'Nigeria',
  },
  {
    label: 'Senegal',
    value: 'Senegal',
  },
  {
    label: 'South Africa',
    value: 'South Africa',
  },
];
