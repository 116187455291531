import { Fallback } from '@/components/fallback';
import { createContext } from '@/lib/context';
import type { Lawyer } from '@/lib/definitions';
import { useUser } from '@/providers/user';
import { useUser as useReactfireUser } from 'reactfire';
import { ref } from 'firebase/database';
import { Navigate, useLocation } from 'react-router-dom';
import { useDatabase, useDatabaseObjectData } from 'reactfire';

type LawyerProvider = {
  lawyer: Lawyer
};

const [useProvider, Provider] = createContext<LawyerProvider>('RequireCompany');

interface RequireLawyerProps extends React.PropsWithChildren {
  isOptional?: boolean;
}

export const RequireLawyer = ({ children, isOptional = false }: RequireLawyerProps) => {
  const { pathname } = useLocation();
  const db = useDatabase();

  const { user } = useUser('RequireLawyer');
  const { data: auth } = useReactfireUser();

  const { status, data: lawyer } = useDatabaseObjectData<Lawyer>(ref(db, `lawyers/${user.uid}`), {
    // @ts-expect-error this is a hack to prevent crash when a user is logged out and has just logged in
    idField: `id-${auth?.stsTokenManager.expirationTime}`,
  });

  if (status === 'loading') {
    return <Fallback />;
  }

  // if (status === 'error') {
  //   return <ErrorScreen />;
  // }

  if (!user && !isOptional) {
    return <Navigate to='/onboarding' state={{ from: pathname }} replace />;
  }

  return (
    <Provider lawyer={lawyer}>
      {children}
    </Provider>
  );
};

export { useProvider as useLawyer };
