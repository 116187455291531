import { useControllableState } from '@/lib/hooks';
import { Popover } from '@/components/ui';
import { cn } from '@/lib/utils';
import { Link } from 'react-router-dom';
import { Bell } from 'lucide-react';
import { Switch } from '@/components/ui/switch';
import { useMemo, useState } from 'react';
import { useNotifications } from '@/lib/hooks/use-notifications';

const isSameDate = (date1: string, date2: string) => {
  return new Date(date1).toDateString() === new Date(date2).toDateString();
};

type NotificationsPopoverProps = {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  children?: React.ReactNode;
};

export const NotificationsPopover = (props: NotificationsPopoverProps) => {
  const [open, onOpenChange] = useControllableState({
    prop: props.open,
    onChange: props.onOpenChange,
    defaultProp: false,
  });
  const [showUnread, setShowUnread] = useState(false);
  const notifications = useNotifications('NotificationModal');

  const formatDate = (date: string) => {
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
    };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const notificationItems = useMemo(() => {
    if (!showUnread) return notifications.data;
    return notifications.data.filter((notification) => !notification.read);
  }, [notifications.data, showUnread]);

  return (
    <Popover open={open} onOpenChange={onOpenChange}>
      {props.children && <Popover.Trigger asChild>{props.children}</Popover.Trigger>}
      <Popover.Content className='w-[500px] max-w-[calc(100vw-1rem)] max-h-[calc(100vh-5rem)] overflow-y-auto p-0'>
        <header className='flex flex-row justify-between sticky top-0 bg-background p-6 pb-3'>
          <h4 className='flex gap-4 items-center'>
            <Bell width={24} height={24} className='text-primary' />
            <span>Notifications</span>
          </h4>
          <div className="flex items-center justify-end gap-4 mr-4">
            <label htmlFor='show-unread' className='text-sm'>Only show unread</label>
            <Switch id='show-unread' checked={showUnread} onCheckedChange={setShowUnread} />
          </div>
        </header>
        <div className='p-6 pt-3 space-y-3'>
          {notifications.data.length > 0 && !showUnread && notifications.data.every((notification) => notification.read) && (
            <div className='bg-secondary/10 p-4 rounded-md text-sm'>
              <span className='hover:underline cursor-pointer' onClick={notifications.cleanUp}>
                Clean up read notifications?
              </span>
            </div>
          )}
          <div className="overflow-y-auto">
            {notificationItems.length === 0 && (
              <div className='grid place-items-center h-24'>
                <p className="text-center text-sm">
                  {showUnread ? 'No unread notifications' : 'No notifications'}
                </p>
              </div>
            )}
            {notificationItems.map((item, index) => (
              <div key={item.id}>
                {index === 0 || !isSameDate(item.createdAt, notificationItems[index - 1].createdAt) ? (
                  <h3 className={cn(
                    'text-sm font-medium text-foreground mb-1',
                    index !== 0 ? 'mt-6' : ''
                  )}>{formatDate(item.createdAt)}</h3>
                ) : null}
                <div className="flex items-start space-x-2">
                  <span className={cn(
                    'inline-block w-2.5 h-2.5 rounded-full mt-2',
                    item.read ? 'bg-primary/20' : 'bg-primary'
                  )} />
                  <div className="flex-1">
                    <Link to={item.actionLink} className="text-sm hover:underline" onClick={() => notifications.readItem(item.id)}>
                      <span>{item.message}</span>
                      {/* {item.people && (
                        <span className="text-primary"> by {item.people.map(person => person.name).join(', ')}</span>
                      )} */}
                    </Link>
                  </div>
                </div>
              </div>
              // <div key={group.date} className="mb-6">
              //   <h3 className="text-sm font-semibold text-foreground">{formatDate(group.date)}</h3>
              //   <ul className="ml-4 mt-2 space-y-2">
              //     {group.items.map((notification) => (
              //       <li key={notification.id} className="flex items-start space-x-2">
              //         <span
              //           className={`inline-block w-2.5 h-2.5 rounded-full mt-2 ${notification.read ? 'bg-primary/20' : 'bg-primary'}`}
              //         ></span>
              //         <div className="flex-1">

              //           <Link to='/' className="text-sm hover:underline">
              //             <span>{notification.message}</span>
              //             {notification.people && (
              //               <span className="text-primary"> by {notification.people.map(person => person.name).join(', ')}</span>
              //             )}
              //           </Link>
              //         </div>
              //       </li>
              //     ))}
              //   </ul>
              // </div>
            ))}
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );
};
