import React from 'react';
import { Button } from '@/components/ui/button';
import { Check, Search } from 'lucide-react';
import { useControllableState } from '@/lib/hooks';
import { CounterPartyDTO } from '@/lib/definitions';
import { Input, Modal } from '@/components/ui';

type CounterPartyListModalProps = {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  children?: React.ReactNode;
  counterParties: CounterPartyDTO[];
  onSelect: (selected: CounterPartyDTO) => void;
};

export const CounterPartyListModal = (props: CounterPartyListModalProps) => {
  const [open, onOpenChange] = useControllableState({
    prop: props.open,
    onChange: props.onOpenChange,
    defaultProp: false,
  });
  const [selected, setSelected] = React.useState<CounterPartyDTO | null>(null);
  const [searchTerm, setSearchTerm] = React.useState('');

  const filteredParties = React.useMemo(() => {
    return props.counterParties.filter(
      (party) =>
        party.orgName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        party.address?.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [props.counterParties, searchTerm]);

  const handleSelect = () => {
    if (selected) {
      props.onSelect(selected);
      onOpenChange(false);
    }
  };

  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      {props.children && <Modal.Trigger asChild>{props.children}</Modal.Trigger>}

      <Modal.Content className='max-w-lg space-y-2'>
        <Modal.Header>
          <Modal.Title className='flex gap-4 items-center'>
            <span>Select Counter Party</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='grid gap-4 py-4'>
          <div className='relative'>
            <Search className='absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground h-4 w-4' />
            <Input
              placeholder='Search counter parties...'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className='pl-9'
            />
          </div>
          <div className='max-h-[400px] overflow-y-auto'>
            {filteredParties.length === 0 ? (
              <div className='text-center py-8 text-muted-foreground'>No counter parties found</div>
            ) : (
              filteredParties.map((party) => (
                <div
                  key={party.id}
                  className={`p-2 border rounded-lg mb-2 cursor-pointer flex items-center justify-between ${
                    selected?.id === party.id
                      ? 'border-primary bg-primary/10'
                      : 'hover:bg-secondary/50'
                  }`}
                  onClick={() => setSelected(party)}
                >
                  <div>
                    <h5 className='font-medium'>{party.orgName}</h5>
                    <p className='text-sm text-muted-foreground'>{party.address}</p>
                  </div>
                  {selected?.id === party.id && <Check className='h-5 w-5 text-primary' />}
                </div>
              ))
            )}
          </div>
          <div className='flex justify-end'></div>
        </Modal.Body>

        <Modal.Footer>
          <Modal.Close asChild>
            <Button size='lg' variant='outline'>
              Cancel
            </Button>
          </Modal.Close>
          <Button onClick={handleSelect} disabled={!selected}>
            Add Selected Party
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
