import { useEffect } from 'react';
import { useVendorStore } from '../providers/vendor';
import { useUser } from 'reactfire';

export const useVendor = () => {
  const { data: user } = useUser();
  const { vendor, loading, fetchVendor } = useVendorStore();

  useEffect(() => {
    if (!user?.uid) {
      useVendorStore.setState({ loading: false });
      return;
    }

    const unsubscribe = fetchVendor(user.uid);
    return () => {
      unsubscribe();
    };
  }, [user, fetchVendor]);

  return [vendor, loading] as const;
};
