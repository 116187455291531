import { CalendarIcon, Cross1Icon, DashboardIcon, PersonIcon } from '@radix-ui/react-icons';
import { VegaIcon } from '@/components/icons';
import { Button } from '@/components/ui';
import { Link, useLocation } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { useUI } from '@/providers/ui';
import { Lock, Rocket, ScrollText } from 'lucide-react';

const links = [
  { name: 'Dashboard', href: '/', icon: DashboardIcon, key: 'dashboard' },
  { name: 'Calendar', href: '/calendar', icon: CalendarIcon, key: 'calendar' },
  // { name: 'Clients', href: '/clients', icon: TeamIcon, key: 'clients' },
  { name: 'Tasks', href: '/tasks', icon: Rocket, key: 'tasks' },
  { name: 'Profile', href: '/profile', icon: PersonIcon, key: 'profile' },
] as const;

const links2 = [
  {
    name: 'Terms of Use',
    href: 'https://firebasestorage.googleapis.com/v0/b/vegabymypocketcounsel.appspot.com/o/assets%2FTerms%20of%20Use%20Agreement%20Final%20Copy.pdf?alt=media&token=c36a9400-1f67-404b-825c-fde9368655d0',
    icon: ScrollText,
  },
  {
    name: 'Privacy Policy',
    href: 'https://firebasestorage.googleapis.com/v0/b/vegabymypocketcounsel.appspot.com/o/assets%2Flawyeredup%2FLawyered%20Up%20Vega%20Privacy%20Policy.pdf?alt=media&token=d5d1fcfc-27c5-45be-9726-b38d60e463b3',
    icon: Lock,
  },
] as const;
const isParentRoute = (pathname: string, href: string) => {
  const path = pathname.endsWith('/') ? pathname : `${pathname}/`;
  const route = href.endsWith('/') ? href : `${href}/`;
  if (route === '/') {
    return path === '/';
  }
  return path.startsWith(route);
};

export const Sidebar = () => {
  const { pathname } = useLocation();
  const toggleSideBar = useUI('Sidebar', (state) => state.toggleSideBar);

  return (
    <div className='flex flex-col h-full'>
      <div className='top-0 pt-6 px-6 pb-3 flex items-center justify-between'>
        <VegaIcon size='sm' />
        <Button
          variant='ghost'
          size='icon'
          className='md:hidden text-foreground'
          onClick={() => toggleSideBar()}
        >
          <Cross1Icon width={24} height={24} />
        </Button>
      </div>
      <ul className='pt-3'>
        {links.map(({ name, href, icon: Icon }) => {
          const isActive = isParentRoute(pathname, href);
          return (
            <li key={name}>
              <Link
                to={href}
                className={cn(
                  'relative flex items-center gap-4 px-6 py-3.5 hover:bg-primary/[0.05] text-sm text-muted-foreground transition-color',
                  {
                    'text-primary bg-primary/[0.05]': isActive,
                    'before:absolute before:content-[""] before:w-2 before:bg-primary': isActive,
                    'before:left-px before:inset-y-px before:rounded-full': isActive,
                  },
                )}
              >
                <Icon className='shrink-0' width={20} height={20} strokeWidth={2} />
                <p>{name}</p>
              </Link>
            </li>
          );
        })}
      </ul>
      <ul className='mt-auto'>
        {links2.map(({ name, href, icon: Icon }) => {
          const isActive = isParentRoute(pathname, href);
          return (
            <li key={name}>
              <Link
                to={href}
                target='_blank'
                className={cn(
                  'relative flex items-center gap-4 px-6 py-3.5 hover:bg-primary/[0.05] text-sm text-muted-foreground transition-color',
                  {
                    'text-primary bg-primary/[0.05]': isActive,
                    'before:absolute before:content-[""] before:w-2 before:bg-primary': isActive,
                    'before:left-px before:inset-y-px before:rounded-full': isActive,
                  },
                )}
              >
                <Icon className='shrink-0' width={20} height={20} strokeWidth={2} />
                <p>{name}</p>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
