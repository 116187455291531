import * as F from '@/lib/firebase';
import { UserId, VegaFile } from '@/lib/definitions';
import { httpsCallable } from 'firebase/functions';
import { collection, doc, updateDoc } from 'firebase/firestore';
import axios from 'axios';

export interface AccessAlert {
  type: string;
  documentName: string;
  docId: string;
  targetEmails?: string[];
}

export const getFileCollection = (companyId: string) =>
  collection(F.firestore, 'companies', companyId, 'files');

export const getFileDocument = (companyId: string, fileId: string) =>
  doc(getFileCollection(companyId), fileId);

type UploadFileParams = {
  name: string;
  parentId: string;
  accessBy: string[];
  file: File;
  mode?: string;
  onProgress?: (progress: number) => void;
}

export const uploadFile = async (
  {
    name,
    parentId,
    accessBy,
    file,
    mode = 'uploaded',
    onProgress,
  } : UploadFileParams) => {
  const getURL = httpsCallable(F.functions, 'file-getUploadURL');
  const getURLResponse = await getURL({ name, parentId, accessBy, mode });

  const { uploadURL, fileId } = getURLResponse.data as { uploadURL: string; fileId: string };
  await axios.put(uploadURL, file, {
    onUploadProgress: (progressEvent) => {
      const progress = progressEvent.total ? (progressEvent.loaded / progressEvent.total) * 100 : 0;
      onProgress?.(progress);
    },
  });

  return fileId;
};

export const deleteFile = async (companyId: string, fileId: string) => {
  const deleteFileFn = httpsCallable(F.functions, 'file-deleteFile');
  await deleteFileFn({ companyId, fileId });
};

type CreateFolderParams = {
  name: string;
  parentId: string;
  accessBy: string[];
  mode: string;
};

type CreateFolderResponse = {
  id: string;
};

export const createFolder = async (
  name: string,
  parentId: string,
  accessBy: string[] = [],
  options: { mode: string } = { mode: 'uploaded' },
) => {
  const createFolderFn = httpsCallable<CreateFolderParams, CreateFolderResponse>(
    F.functions,
    'file-createFolder',
  );
  const response = await createFolderFn({
    name,
    parentId,
    accessBy,
    mode: options.mode,
  });
  return response.data.id;
};

export const renameFile = async (companyId: string, fileId: string, name: string) => {
  const fileRef = getFileDocument(companyId, fileId);
  await updateDoc(fileRef, { name });
};

export const updateFileAccess = async (fileId: string, accessBy: UserId[]) => {
  const updateFileFn = httpsCallable(F.functions, 'file-updateFile');
  await updateFileFn({ fileId, accessBy });
};

export const updateFileStatus = async (companyId: string, fileId: string, status: string) => {
  const fileRef = getFileDocument(companyId, fileId);
  await updateDoc(fileRef, { status } as Partial<VegaFile>);
};

export const exportFiles = async (companyId: string) => {
  const getAnalytics = httpsCallable(F.functions, 'analytics-exportFiles');
  const result = await getAnalytics({ companyId });
  return result;
};

export const shareFile = async (fileId: string, emails: string[], access: string) => {
  const shareFileFn = httpsCallable(F.functions, 'file-shareFile');
  const result = await shareFileFn({ fileId, emails, access });
  return result;
};
