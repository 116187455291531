import { Button, Modal, Input } from '@/components/ui';
import { useControllableState, usePromise } from '@/lib/hooks';
import { getAuth, updatePassword } from 'firebase/auth';
import { getDatabase, ref, update } from 'firebase/database';
import { app } from '@/lib/firebase';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

type UpdatePasswordModalProps = {
  open?: boolean;
  isOpen: boolean;
  onOpenChange?: (open: boolean) => void;
  children?: React.ReactNode;
};

const updatePasswordSchema = z.object({
  password: z.string().min(6, 'Password must be at least 6 characters'),
});

type UpdatePasswordDTO = z.infer<typeof updatePasswordSchema>;

export const UpdatePasswordModal = (props: UpdatePasswordModalProps) => {
  const [_open, onOpenChange] = useControllableState({
    prop: props.open,
    onChange: props.onOpenChange,
    defaultProp: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<UpdatePasswordDTO>({
    resolver: zodResolver(updatePasswordSchema),
  });

  const { isOpen } = props;
  const auth = getAuth(app);
  const db = getDatabase(app);

  const [submit, isSubmitting] = usePromise(
    handleSubmit(async (data: { password: string }) => {
      const user = auth.currentUser;
      const newPassword = data.password;
      const userRef = ref(db, `users/${user?.uid}`);

      if (user) {
        try {
          // Change the user's password
          await updatePassword(user, newPassword);

          // Update the 'updatedPassword' node in Realtime Database
          await update(userRef, { updatedPassword: true });

          // Reset the form and close the modal
          reset();
          onOpenChange(false);
        } catch (error: any) {
          console.error('Error updating password:', error.message);
        }
      }
    }),
  );

  return (
    <Modal.Root open={isOpen} onOpenChange={onOpenChange}>
      {props.children && <Modal.Trigger asChild>{props.children}</Modal.Trigger>}
      <Modal.Content className='max-w-xl space-y-2'>
        <header className='px-8 py-8 sticky top-0 bg-background z-10'>
          <Modal.Title className='flex gap-4 items-center'>
            <span>Update your password, to something you can easily remember.</span>
          </Modal.Title>
        </header>
        <form className='px-8 space-y-12' onSubmit={submit}>
          <div className='space-y-6'>
            <Input
              label='Update Password'
              {...register('password')}
              error={errors.password?.message}
              type='password'
            />
          </div>
          <div className='flex justify-end space-x-2 mt-4 sticky bottom-0 bg-background py-6 border-t border-muted'>
            <Button
              size='lg'
              type='submit'
              className='bg-primary hover:bg-primary/70 text-white'
              loading={isSubmitting}
            >
              Update Password
            </Button>
          </div>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};
