import { Fallback } from '@/components/fallback';
import { createContext } from '@/lib/context';
import type { Vendor } from '@/lib/definitions';
import { useUser } from '@/providers/user';
import { useUser as useReactfireUser } from 'reactfire';
import { ref } from 'firebase/database';
import { Navigate, useLocation } from 'react-router-dom';
import { useDatabase, useDatabaseObjectData } from 'reactfire';

type VendorProvider = {
  vendor: Vendor
};

interface RequireVendorProps extends React.PropsWithChildren {
  isOptional?: boolean;
}

const [useProvider, Provider] = createContext<VendorProvider>('RequireCompany');

export const RequireVendor = ({ children, isOptional = false }: RequireVendorProps) => {
  const { pathname } = useLocation();
  const db = useDatabase();

  const { user } = useUser('RequireVendor');
  const { data: auth } = useReactfireUser();

  const { status, data: vendor } = useDatabaseObjectData<Vendor>(ref(db, `vendors/${user.uid}`), {
    // @ts-expect-error this is a hack to prevent crash when a user is logged out and has just logged in
    idField: `id-${auth?.stsTokenManager.expirationTime}`,
  });

  if (status === 'loading') {
    return <Fallback />;
  }

  // if (status === 'error') {
  //   return <ErrorScreen />;
  // }

  if (!vendor && !isOptional) {
    return <Navigate to='/onboarding' state={{ from: pathname }} replace />;
  }

  return (
    <Provider vendor={vendor}>
      {children}
    </Provider>
  );
};

export { useProvider as useVendor };
