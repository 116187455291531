import { InactivityDialog } from '@/components/inactivity-dialog';
import { useEffect, useState } from 'react';
import { create } from 'zustand';

interface ActivityStore {
  lastActivity: number;
  updateActivity: () => void;
  openInactivityDialog: boolean;
  setOpenInactivityDialog: (open: boolean) => void;
}

const useActivityStore = create<ActivityStore>((set) => ({
  lastActivity: Date.now(),
  updateActivity: () => set({
    lastActivity: Date.now(),
    openInactivityDialog: false,
  }),
  openInactivityDialog: false,
  setOpenInactivityDialog: (open) => set({ openInactivityDialog: open }),
}));

interface ActivityProviderProps {
  timeout?: number;
  logout: () => void;
  children: React.ReactNode;
}

const DEFAULT_TIMEOUT = 1000 * 60 * 15;

export const ActivityProvider = ({ timeout = DEFAULT_TIMEOUT, logout, children }: ActivityProviderProps) => {
  const { updateActivity, lastActivity, openInactivityDialog, setOpenInactivityDialog } = useActivityStore();

  // Update activity on mount
  useEffect(() => {
    updateActivity();
  }, [updateActivity]);

  useEffect(() => {
    const handleUserActivity = () => {
      if (!openInactivityDialog) {
        updateActivity();
      }
    };

    const activityEvents = ['mousemove', 'keydown', 'click'];
    activityEvents.forEach((event) =>
      window.addEventListener(event, handleUserActivity)
    );

    const checkInactivity = setInterval(() => {
      const timeRemaining = timeout - (Date.now() - lastActivity);

      if (timeRemaining <= 0) {
        setOpenInactivityDialog(false);
        logout();
      } else if (timeRemaining <= 30000) {
        setOpenInactivityDialog(true);
      }
    }, 1000);

    return () => {
      activityEvents.forEach((event) =>
        window.removeEventListener(event, handleUserActivity)
      );
      clearInterval(checkInactivity);
    };
  }, [lastActivity, openInactivityDialog, logout, timeout, updateActivity, setOpenInactivityDialog]);

  return (
    <>
      {children}
      <InactivityDialog timeout={timeout} open={openInactivityDialog} logout={logout} />
    </>
  );
};

export const useActivity = (timeout: number) => {
  const { lastActivity, updateActivity } = useActivityStore();
  const [inactiveTimeRemaining, setInactiveTimeRemaining] = useState(timeout);

  useEffect(() => {
    const interval = setInterval(() => {
      const remaining = timeout - (Date.now() - lastActivity);
      setInactiveTimeRemaining(remaining);
    }, 1000);

    return () => clearInterval(interval);
  }, [lastActivity, timeout]);

  return {
    inactiveTimeRemaining,
    lastActivity,
    extendActivity: updateActivity,
  };
};
