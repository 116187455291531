import { createContext } from 'react';
import { JsonPage } from './types';
import { CounterPartyDTO } from '@/lib/definitions';

export type FormBuilderContextType = {
  extra: {
    user?: any;
    company?: any;
    [key: string]: any;
  };
  schema: JsonPage;
  state: Record<string, any>;
  counterparties?: CounterPartyDTO[];
  counterpartiesLoading?: boolean;
};

export const FormBuilderContext = createContext<FormBuilderContextType>(undefined as any);
