import { createContext } from '@/lib/context';
import type { Company } from '@/lib/definitions';
import { useUser } from '@/providers/user';
import { useUser as useReactfireUser } from 'reactfire';
import { ref } from 'firebase/database';
import { Navigate, useLocation } from 'react-router-dom';
import { useDatabase, useDatabaseObjectData } from 'reactfire';
import { Fallback } from '@/components/fallback';

type CompanyProvider = {
  activeCompany: Company
  companies: Record<string, true>
};

const [useProvider, Provider] = createContext<CompanyProvider>('RequireCompany');

interface RequireCompanyProps extends React.PropsWithChildren {
  isOptional?: boolean
}

export const RequireCompany = ({ children, isOptional }: RequireCompanyProps) => {
  const { pathname } = useLocation();
  const db = useDatabase();

  const { user } = useUser('RequireCompany');
  const { data: auth } = useReactfireUser();

  const { status, data: company } = useDatabaseObjectData<Company>(ref(db, `companies/${user.activeCompanyId}`), {
    // @ts-expect-error this is a hack to prevent crash when a user is logged out and has just logged in
    idField: `id-${auth?.stsTokenManager.expirationTime}`,
  });

  if (status === 'loading') {
    return <Fallback />;
  }

  // if (status === 'error') {
  //   return <ErrorScreen />;
  // }

  if (!user && !isOptional) {
    return <Navigate to='/onboarding' state={{ from: pathname }} replace />;
  }

  return (
    <Provider activeCompany={company} companies={user.companies}>
      {children}
    </Provider>
  );
};

export { useProvider as useCompany };
