export const isPEMFormat = (key: string) => {
  const pemRegex = /-----BEGIN PUBLIC KEY-----(.*?)-----END PUBLIC KEY-----/s;
  const matches = key.match(pemRegex);

  // Check if there's a match and capture group (the public key content between the header and footer)
  if (matches && matches[1]) {
    const base64Content = matches[1].replace(/[\r\n\s]/g, ''); // Remove any whitespace/newlines within the content

    // Validate that the content is base64 encoded
    return /^[A-Za-z0-9+/=]+$/.test(base64Content);
  }

  return false;
};