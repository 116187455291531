import { useEffect } from 'react';
import { useVendor } from './use-vendor';

/**
 * Set vendor styles on the document root
 */
export const useVendorStyles = () => {
  const [vendor] = useVendor();

  useEffect(() => {
    let style = {
      '--primary': '',
      '--primary-foreground': '',
      // 'secondary': '',
      // 'secondary-foreground': 
    };

    if (vendor) {
      style = {
        '--primary': vendor.branding.primaryColor,
        '--primary-foreground': '255 255 255',
        // 'secondary': vendor.branding.secondaryColor,
        // 'secondary-foreground': '255 255 255',
      };
    }

    Object.entries(style).forEach(([key, value]) => {
      if (value) {
        document.documentElement.style.setProperty(key, value);
      } else {
        document.documentElement.style.removeProperty(key);
      }
    });
  }, [vendor]);
};