import { Fallback } from '@/components/fallback';
import { Navigate, useLocation } from 'react-router-dom';
import { useSigninCheck } from 'reactfire';

export const RequireAuth = ({ children }: React.PropsWithChildren) => {
  const { pathname } = useLocation();
  const { status, data: signInCheckResult } = useSigninCheck({ suspense: true });

  if (!children) {
    throw new Error('Children must be provided');
  }

  if (status === 'loading') {
    return <Fallback />;
  }

  // if (status === 'error') {
  //   return <ErrorScreen />;
  // }

  if (signInCheckResult.signedIn === true) {
    return children as JSX.Element;
  } else {
    return <Navigate to='/login' state={{ from: pathname }} replace />;
  }
};