/**
 * Returns the last part of the path
 * @param s the given path string
 * @returns the last part of the path
 */
export const basename = (s: string): string => {
  const slash = s.lastIndexOf('/');
  s = s.slice(slash + 1);
  const dot = s.lastIndexOf('.');
  if (dot >= 0) {
    s = s.slice(0, dot);
  }
  return s;
};

/**
 * Returns the extension of the path
 * @param s the given path string
 * @returns the extension of the path
 */
export const getExtension = (s: string): string => {
  const dot = s.lastIndexOf('.');
  if (dot >= 0) {
    return s.slice(dot + 1);
  }
  return '';
};
