import { useMemo } from 'react';

type AvatarProps = {
  size?: number | string;
  name?: string;
};

export const Avatar = ({ size = '2.5rem', name }: AvatarProps) => {
  const d = useMemo(() => {
    if (typeof size === 'string') return size;
    return size + 'px';
  }, [size]);

  return (
    <div
      style={{ width: d, height: d }}
      className='w-10 h-10 capitalize shrink-0 flex justify-center items-center bg-primary/10 rounded-full'
    >
      {name?.[0]? name?.[0]: <img src='https://firebasestorage.googleapis.com/v0/b/vegabymypocketcounsel.appspot.com/o/assets%2FLAWYERED_UP_POWERED_BY_VEGA-removebg-preview%20(1).png?alt=media&token=7d5a9556-8bf8-4cbe-a777-beaab25e95ac'/>}
    </div>
  );
};
