import { ReactFireGlobals } from 'reactfire';

export const clearPreloadables = () => {
  console.log('Clearing preloaded observables');
  if (!(globalThis as any as ReactFireGlobals)._reactFirePreloadedObservables) {
    console.log('No preloaded observables found');
    return;
  }
  const preloadedObservables = (globalThis as any as ReactFireGlobals)
    ._reactFirePreloadedObservables;
  preloadedObservables.clear();
};
