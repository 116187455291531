import { VegaIcon } from '@/components/icons';

export const AuthLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='flex min-h-screen bg-primary items-center justify-center [&_a]:text-info'>
      <div className='w-full max-w-md bg-white border-gray-300 rounded-lg shadow-lg px-4' style={{
        marginTop:'2rem', marginBottom:'1.5rem'
      }}>
        <div className='w-full mx-auto flex flex-col gap-10 py-8 px-4'>
          <div className='flex gap-3 justify-center items-center'>
            <VegaIcon size='md' />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
