import React from 'react';
import Select, { components } from 'react-select';
import { ChevronDownIcon, EraserIcon } from '@radix-ui/react-icons';

interface Option {
  value: string;
  label: string;
}

interface MultiSelectProps {
  options: Option[];
  value: string[];
  onChange: (value: string[]) => void;
  placeholder?: string;
  error?: string;
}

const MultiSelect: React.FC<MultiSelectProps> = ({ options, value, onChange, placeholder }) => {
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: '0.9rem',
      padding: '0.375rem .5rem',
      backgroundColor: 'transparent',
      width: '100%',
      height: '100%',
      boxShadow: 'none',
      borderColor: '#6b6b6a',
      border: '1px solid #6b6b6a',
      '&:hover': {
        borderColor: '#6b6b6a',
      },
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: '#002147',
      borderRadius: '1rem',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: 'white',
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      color: 'white',
    }),
  };

  const DropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon className="w-5 h-5" />
    </components.DropdownIndicator>
  );

  const MultiValueRemove = (props: any) => (
    <components.MultiValueRemove {...props}>
      <EraserIcon className="w-4 h-4" />
    </components.MultiValueRemove>
  );

  return (
    <Select
      options={options}
      value={options.filter(option => value.includes(option.value))}
      onChange={(selectedOptions: any) => onChange(selectedOptions.map((option: Option) => option.value))}
      isMulti
      placeholder={placeholder}
      styles={customStyles}
      components={{ DropdownIndicator, MultiValueRemove }}
    />
  );
};

export default MultiSelect;
