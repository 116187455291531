import { Core } from '@pdftron/webviewer';

export async function getBlobFromAnnotation(
  annotation: Core.Annotations.Annotation,
  documentViewer: Core.DocumentViewer,
): Promise<Blob | null> {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    canvas.height = annotation.Height;
    canvas.width = annotation.Width;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return reject(null);
    }

    const pageMatrix = documentViewer.getDocument().getPageMatrix(annotation.PageNumber);
    ctx.translate(-annotation.X, -annotation.Y);
    annotation.draw(ctx, pageMatrix);
    canvas.toBlob((blob) => {
      // canvas.remove();
      resolve(blob);
    }, 'image/png');
  });
}

export function convertBlobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result !== 'string') {
        return reject(new Error('Failed to read blob as base64 string.'));
      }
      // resolve(reader.result.split(',')[1]); // Extract base64 string from data URL
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(new Error('Failed to read blob as base64 string.'));
    };
    reader.readAsDataURL(blob);
  });
}
