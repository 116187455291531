import * as F from '@/lib/firebase';
import { Company, CounterPartyDTO as CounterParty, User } from '@/lib/definitions';
import { push, ref, set, update } from 'firebase/database';
import { httpsCallable } from 'firebase/functions';

export interface CounterPartyDTO {
  orgName: string;
  counterpartyType: string;
  address?: string;
  country?: string;
  rcNumber?: string;
  companyType?: string;
}

export const createCounterParty = async (company: Company, user: User, data: CounterPartyDTO) => {
  const newCounterPartyRef = await push(ref(F.db, `counterparties/${company.id}`));
  const timeAt = new Date().toISOString();
  const isCompany = data.counterpartyType === 'company';

  const counterparty = {
    id: newCounterPartyRef.key,
    orgName: data.orgName,
    counterpartyType: data.counterpartyType,
    country: data.country,
    address: data.address,
    modifiedBy: user.uid,
    addedBy: user.uid,
    lastModifiedOn: timeAt,
  } as CounterParty;

  const companyCounterparty = {
    id: newCounterPartyRef.key,
    orgName: data.orgName,
    counterpartyType: data.counterpartyType,
    country: data.country,
    address: data.address,
    modifiedBy: user.uid,
    rcNumber: data.rcNumber,
    companyType: data.companyType,
    addedBy: user.uid,
    lastModifiedOn: timeAt,
  } as CounterParty;

  const setData = isCompany ? companyCounterparty : counterparty;

  await set(newCounterPartyRef, setData);
};

export type UpdateCounterpartyDTO = Partial<CounterPartyDTO>;

export const updateCounterparty = async (
  company: Company,
  user: User,
  counterPartyId: string,
  data: UpdateCounterpartyDTO,
) => {
  const counterPartyRef = ref(F.db, `counterparties/${company.id}/${counterPartyId}`);
  const timeAt = new Date().toISOString();
  const isCompany = data.counterpartyType === 'company';

  const counterparty = {
    orgName: data.orgName,
    counterpartyType: data.counterpartyType,
    country: data.country,
    address: data.address,
    modifiedBy: user.uid,
    lastModifiedOn: timeAt,
  } as Partial<CounterParty>;

  const companyCounterparty = {
    orgName: data.orgName,
    counterpartyType: data.counterpartyType,
    country: data.country,
    address: data.address,
    modifiedBy: user.uid,
    rcNumber: data.rcNumber,
    companyType: data.companyType,
    lastModifiedOn: timeAt,
  } as Partial<CounterParty>;

  const updates = isCompany ? companyCounterparty : counterparty;

  await update(counterPartyRef, updates);
};

export const deleteCounterParty = async (companyId: string, counterPartyId: string) => {
  const deleteCounterpartyFn = httpsCallable(F.functions, 'deleteCounterparty');
  await deleteCounterpartyFn({ companyId, counterPartyId });
};
