import { Button, Input, Modal } from '@/components/ui';
import { VegaFile } from '@/lib/definitions';
import { useControllableState } from '@/lib/hooks';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { FileTextIcon } from '@radix-ui/react-icons';
import { z } from 'zod';

type SaveAsModalProps = {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  children?: React.ReactNode;
  file: VegaFile;
  onSave: (newName: string) => Promise<void>;
};

const schema = z.object({
  name: z.string().min(1, 'Name is required'),
});

type DTO = z.infer<typeof schema>;

export const SaveAsModal = (props: SaveAsModalProps) => {
  const [open, onOpenChange] = useControllableState({
    prop: props.open,
    onChange: props.onOpenChange,
    defaultProp: false,
  });

  const form = useForm<DTO>({
    resolver: zodResolver(schema),
    defaultValues: { name: '' },
  });

  const handleSave = async (data: DTO) => {
    try {
      await props.onSave(data.name);
      onOpenChange(false); // Close modal after successful save
    } catch (error) {
      console.error('Save failed', error);
    }
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      form.reset();
    }
    onOpenChange(open);
  };

  return (
    <Modal.Root open={open} onOpenChange={handleOpenChange}>
      {props.children && <Modal.Trigger asChild>{props.children}</Modal.Trigger>}

      <Modal.Content>
        <Modal.Header>
          <div className='flex gap-4 items-center'>
            <FileTextIcon className='w-7 h-7 text-primary' />
            <Modal.Title>Save As</Modal.Title>
          </div>
        </Modal.Header>
        <form onSubmit={form.handleSubmit(handleSave)}>
          <Modal.Body className='space-y-8'>
            <Modal.Description>
              You have chosen to save {props?.file?.name} as a new document. Please enter a new
              name:
            </Modal.Description>
            <div className='flex flex-col gap-4'>
              <Input label='Name' {...form.register('name')} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Modal.Close asChild>
              <Button size='lg' variant='outline'>
                Cancel
              </Button>
            </Modal.Close>
            <Button size='lg' type='submit' loading={form.formState.isSubmitting}>
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};
