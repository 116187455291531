import { Button } from '@/components/ui';
import { ClientErrorSvg } from '@/features/company/components/views';

export const ErrorPage = () => {
  return (
    <div className='w-screen h-screen flex flex-col justify-center items-center gap-6'>
      <ClientErrorSvg />
      <p className='text-lg'>Uh-oh! Something broke.</p>
      <Button variant='outline' onClick={() => window.location.reload()}>
        Refresh
      </Button>
    </div>
  );
};
