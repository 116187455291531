import { RouteObject } from 'react-router-dom';
import { __PRODUCT__ } from '@/lib/hooks/use-product';
import { companyRoutes } from '@/features/company/router';
import { lawyerRoutes } from '@/features/lawyer/router';
import { vendorRoutes } from '@/features/vendor/router';

const routeMapping: Record<string, RouteObject[]> = {
  'vega-company': companyRoutes,
  'vega-lawyer': lawyerRoutes,
  'vega-vendor': vendorRoutes,
};

export const protectedRoutes: RouteObject[] = routeMapping[__PRODUCT__];
