import { Svg, SvgProps } from './Svg';

export const Spinner = (props: SvgProps) => (
  <Svg size={28} {...props} fill='none' viewBox='0 0 24 24'>
    <path
      d='M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z'
      opacity='.25'
      fill='currentColor'
    />
    <path
      d='M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z'
      className='origin-center animate-spin'
      fill='currentColor'
    />
  </Svg>
);

export const HandWaveIcon = (props: SvgProps) => (
  <Svg {...props} viewBox='0 0 40 40' fill='none'>
    <path
      d='M38.3334 28.3333C38.3334 33.85 33.8501 38.3333 28.3334 38.3333V35.8333C32.5001 35.8333 35.8334 32.5 35.8334 28.3333H38.3334ZM1.66678 11.6667C1.66678 6.15001 6.15011 1.66667 11.6668 1.66667V4.16667C7.50011 4.16667 4.16678 7.50001 4.16678 11.6667H1.66678ZM13.3334 7.20001L5.68345 14.8667C0.31678 20.2333 0.31678 28.95 5.68345 34.3167C11.0501 39.6833 19.7668 39.6833 25.1334 34.3167L36.9168 22.5C37.7334 21.7167 37.7334 20.4 36.9168 19.5833C36.7233 19.3893 36.4934 19.2354 36.2403 19.1304C35.9871 19.0253 35.7158 18.9713 35.4418 18.9713C35.1677 18.9713 34.8964 19.0253 34.6433 19.1304C34.3902 19.2354 34.1603 19.3893 33.9668 19.5833L26.6001 26.95L25.4168 25.7667L36.3168 14.8667C37.1334 14.05 37.1334 12.7333 36.3168 11.9167C35.5001 11.1 34.1668 11.1 33.3334 11.9167L23.6501 21.6667L22.5001 20.45L33.9501 8.96667C34.7668 8.15001 34.7668 6.83334 33.9501 6.01667C33.1334 5.20001 31.8168 5.20001 31.0001 6.01667L19.5168 17.5L18.3334 16.3333L27.5001 7.20001C28.3334 6.38334 28.3334 5.06667 27.5001 4.25001C26.6668 3.43334 25.3668 3.43334 24.5501 4.25001L11.8501 16.95C12.8445 18.2327 13.3372 19.8339 13.236 21.4538C13.1348 23.0737 12.4465 24.6011 11.3001 25.75L10.1168 24.5667C11.0531 23.6292 11.579 22.3583 11.579 21.0333C11.579 19.7083 11.0531 18.4375 10.1168 17.5L9.53345 16.9167L16.3168 10.1333C17.1334 9.31667 17.1334 8.00001 16.3168 7.18334C15.4834 6.38334 14.1668 6.38334 13.3334 7.20001Z'
      fill='currentColor'
    />
  </Svg>
);

export const FolderIcon = (props: SvgProps) => (
  <Svg {...props} fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor'>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z'
    />
  </Svg>
);

export const MailIcon = (props: SvgProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 30 30'
    fill='none'
    width={30}
    height={30}
    {...props}
  >
    <path
      d='M25 5H5C3.625 5 2.5125 6.125 2.5125 7.5L2.5 22.5C2.5 23.875 3.625 25 5 25H25C26.375 25 27.5 23.875 27.5 22.5V7.5C27.5 6.125 26.375 5 25 5ZM24.5 10.3125L15.6625 15.8375C15.2625 16.0875 14.7375 16.0875 14.3375 15.8375L5.5 10.3125C5.37466 10.2421 5.2649 10.1471 5.17736 10.0331C5.08982 9.91906 5.02633 9.78847 4.99072 9.64921C4.95511 9.50995 4.94813 9.36492 4.9702 9.22288C4.99226 9.08085 5.04292 8.94477 5.1191 8.82288C5.19528 8.70099 5.29541 8.59582 5.41341 8.51374C5.53141 8.43167 5.66484 8.37439 5.80562 8.34537C5.9464 8.31636 6.0916 8.31621 6.23244 8.34494C6.37328 8.37366 6.50683 8.43067 6.625 8.5125L15 13.75L23.375 8.5125C23.4932 8.43067 23.6267 8.37366 23.7676 8.34494C23.9084 8.31621 24.0536 8.31636 24.1944 8.34537C24.3352 8.37439 24.4686 8.43167 24.5866 8.51374C24.7046 8.59582 24.8047 8.70099 24.8809 8.82288C24.9571 8.94477 25.0077 9.08085 25.0298 9.22288C25.0519 9.36492 25.0449 9.50995 25.0093 9.64921C24.9737 9.78847 24.9102 9.91906 24.8226 10.0331C24.7351 10.1471 24.6253 10.2421 24.5 10.3125Z'
      fill='#25B061'
    />
  </svg>
);

export const TeamIcon = () => (
  <svg width='18' height='13' viewBox='0 0 18 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.8915 5.67496C10.3977 4.96768 10.6699 4.11972 10.6699 3.24996C10.6699 2.3802 10.3977 1.53224 9.8915 0.824963C10.3653 0.501592 10.9262 0.330102 11.4998 0.333296C12.2734 0.333296 13.0153 0.640587 13.5622 1.18757C14.1092 1.73455 14.4165 2.47641 14.4165 3.24996C14.4165 4.02351 14.1092 4.76538 13.5622 5.31236C13.0153 5.85934 12.2734 6.16663 11.4998 6.16663C10.9262 6.16982 10.3653 5.99833 9.8915 5.67496ZM3.58317 3.24996C3.58317 2.6731 3.75423 2.10919 4.07472 1.62955C4.3952 1.14991 4.85073 0.77607 5.38368 0.555314C5.91663 0.334558 6.50307 0.276799 7.06885 0.389339C7.63463 0.501879 8.15433 0.779665 8.56223 1.18757C8.97013 1.59547 9.24792 2.11517 9.36046 2.68095C9.473 3.24673 9.41524 3.83317 9.19449 4.36612C8.97373 4.89907 8.59989 5.3546 8.12025 5.67508C7.64061 5.99557 7.0767 6.16663 6.49984 6.16663C5.72629 6.16663 4.98442 5.85934 4.43744 5.31236C3.89046 4.76538 3.58317 4.02351 3.58317 3.24996ZM5.24984 3.24996C5.24984 3.49719 5.32315 3.73886 5.4605 3.94442C5.59785 4.14999 5.79308 4.3102 6.02148 4.40481C6.24989 4.49942 6.50122 4.52418 6.7437 4.47594C6.98618 4.42771 7.20891 4.30866 7.38372 4.13385C7.55854 3.95903 7.67759 3.7363 7.72582 3.49383C7.77405 3.25135 7.7493 3.00002 7.65469 2.77161C7.56008 2.5432 7.39986 2.34798 7.1943 2.21063C6.98874 2.07327 6.74706 1.99996 6.49984 1.99996C6.16832 1.99996 5.85037 2.13166 5.61595 2.36608C5.38153 2.6005 5.24984 2.91844 5.24984 3.24996ZM12.3332 11.1666V12.8333H0.666504V11.1666C0.666504 11.1666 0.666504 7.8333 6.49984 7.8333C12.3332 7.8333 12.3332 11.1666 12.3332 11.1666ZM10.6665 11.1666C10.5498 10.5166 9.55817 9.49996 6.49984 9.49996C3.4415 9.49996 2.3915 10.5916 2.33317 11.1666M12.2915 7.8333C12.8023 8.23057 13.2199 8.73484 13.515 9.31069C13.8101 9.88654 13.9756 10.52 13.9998 11.1666V12.8333H17.3332V11.1666C17.3332 11.1666 17.3332 8.14163 12.2832 7.8333H12.2915Z'
      fill='#666666'
    />
  </svg>
);
