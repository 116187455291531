// stack overflow https://stackoverflow.com/a/56678483/12220799
// gist https://gist.github.com/mnpenner/70ab4f0836bbee548c71947021f93607

type RGB = [r: number, g: number, b: number, a?: number];
type RGBA = [r: number, g: number, b: number, a: number];
const UNK = 255 / 2;

/**
 * Parse string to number with radix.
 *
 * @param str string to parse
 * @param radix radix to use
 * @param fallback fallback value if parsing fails
 * @returns parsed number or fallback
 */
function parseRadix(str: string, fallback: number) {
  const n = parseInt(str, 16);
  return isNaN(n) ? fallback : n;
}

/**
 * Convert RGB hex string to RGB tuple.
 *
 * @param hex RGB hex string like "#CCCFDB"
 * @returns RGB tuple in [0-255]
 */
export function hexToRgba(hex: string): RGBA {
  if (/^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex)) {
    const chunkSize = Math.floor((hex.length - 1) / 3);
    const hexArr = hex.slice(1).match(new RegExp(`.{${chunkSize}}`, 'g'));
    if (hexArr) {
      const [r, g, b, a] = hexArr.map((h) => parseRadix(h.repeat(2 / h.length), UNK));
      if (a !== undefined) {
        const alpha = parseFloat((a / 255).toFixed(2));
        return [r, g, b, alpha];
      }
      return [r, g, b, 1];
    }
  }
  return [UNK, UNK, UNK, 1];
}

/**
 * Convert RGB tuple to RGB hex string.
 *
 * @param rgb RGB tuple in [0-255]
 * @returns RGB hex string like "#CCCFDB"
 */
export function rgbaToHex([r, g, b, a = 1]: RGB): string {
  const alpha =
    a === 1
      ? ''
      : Math.round(a * 255)
        .toString(16)
        .padStart(2, '0');
  const hex = [r, g, b].map((n) => n.toString(16).padStart(2, '0'));
  return `#${hex.join('')}${alpha}`;
}

function sRGBtoLin(colorChannel: number) {
  // Send this function a decimal sRGB gamma encoded color value
  // between 0.0 and 1.0, and it returns a linearized value.

  if (colorChannel <= 0.04045) {
    return colorChannel / 12.92;
  }

  return Math.pow((colorChannel + 0.055) / 1.055, 2.4);
}

/**
 * @param r Red, [0-1]
 * @param g Green, [0-1]
 * @param b Blue, [0-1]
 * @returns Luminance, [0-1]
 */
function rgbToY(r: number, g: number, b: number) {
  return 0.2126 * sRGBtoLin(r) + 0.7152 * sRGBtoLin(g) + 0.0722 * sRGBtoLin(b);
}

/**
 * Luminance to perceived lightness.
 *
 * @param Y Luminance, [0-1]
 */
function YtoLstar(Y: number) {
  // Send this function a luminance value between 0.0 and 1.0,
  // and it returns L* which is "perceptual lightness"

  if (Y <= 216 / 24389) {
    // The CIE standard states 0.008856 but 216/24389 is the intent for 0.008856451679036
    return Y * (24389 / 27); // The CIE standard states 903.3, but 24389/27 is the intent, making 903.296296296296296
  }

  return Math.pow(Y, 1 / 3) * 116 - 16;
}

/**
 * Calculate perceived lightness from RGB hex string.
 *
 * @param rgb RGB hex string like "#CCCFDB" or css string like "rgb(204, 207, 219)" or "rgba(204, 207, 219, 0.5)"
 * @returns Lightness value, [0-100].
 */
function rgbHexToLightness(rgb: string) {
  const [r, g, b] = hexToRgba(rgb);
  return YtoLstar(rgbToY(r / 255, g / 255, b / 255));
}

/**
 * Convert RGB hex string to RGB tuple.
 *
 * @param rgb RGB css string like "rgb(204, 207, 219)" or "rgba(204, 207, 219, 0.5)"
 * @returns RGB array like [204, 207, 219, 0.5]
 */
export const rgbaStringToArray = (color: string): RGBA => {
  const rgbaRegex = /rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d*\.?\d+)\)/;
  const matches = color.match(rgbaRegex);
  if (matches) {
    const [_, r, g, b, a] = matches;
    return [parseInt(r), parseInt(g), parseInt(b), a ? parseFloat(a) : 1];
  }
  const rgbRegex = /rgb\((\d+),\s*(\d+),\s*(\d+)\)/;
  const rgbMatches = color.match(rgbRegex);
  if (rgbMatches) {
    const [_, r, g, b] = rgbMatches;
    return [parseInt(r), parseInt(g), parseInt(b), 1];
  }
  return [UNK, UNK, UNK, 1];
};

/**
 * Merge two colors with alpha channel.
 *
 * @param over top color
 * @param under bottom color
 * @returns merged color
 */
function mergeAlpha(over: RGBA, under: RGBA): RGBA {
  const [r1, g1, b1, a1 = 1] = over;
  const [r2, g2, b2, a2 = 1] = under;
  const a = a1 + a2 * (1 - a1);
  const r = (r1 * a1 + r2 * a2 * (1 - a1)) / a;
  const g = (g1 * a1 + g2 * a2 * (1 - a1)) / a;
  const b = (b1 * a1 + b2 * a2 * (1 - a1)) / a;
  return [r, g, b, a];
}

/**
 * Returns a hex string for a given CSS color.
 *
 * @param colorName any valid CSS color
 * @returns RGB hex string like "#CCCFDB"
 */
export const resolveColorToHex = (colorName: string) => {
  const el = document.createElement('div');
  el.style.color = colorName;
  document.body.appendChild(el);
  const color = window.getComputedStyle(el).color;
  document.body.removeChild(el);
  // convert rgb to hex
  const rgba = rgbaStringToArray(color);
  const under = [255, 255, 255, 1] as RGBA;
  const alpha = rgba[3];
  if (alpha === 0) {
    return rgbaToHex(under);
  } else if (alpha < 1) {
    return rgbaToHex(mergeAlpha(rgba, under));
  } else {
    return rgbaToHex(rgba);
  }
};

/**
 * Calculate perceived lightness from CSS color.
 *
 * @param color any valid CSS color
 * @returns Lightness value, [0-100].
 */
export const colorToLightness = (color: string) => {
  const hex = resolveColorToHex(color);
  const lightness = rgbHexToLightness(hex);

  // return suitable neutral color for lightness
  return lightness > 40 ? 'black' : 'white';
};
