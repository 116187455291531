import { useCallback, useEffect, useState } from 'react';

export const usePropertyValue = <T,>(
  value: T | undefined,
  onChange: (t: React.SetStateAction<T>) => void,
  defaultValue: T | (() => T),
  transform?: (t: T) => T,
) => {
  const [internalValue, setInternalValue] = useState<T>(() => {
    if (value) {
      return transform ? transform(value) : value;
    } else {
      if (typeof defaultValue === 'function') {
        return (defaultValue as () => T)();
      }
      return defaultValue;
    }
  });

  useEffect(() => {
    onChange(internalValue);
  }, [internalValue]);

  const handleChange = useCallback(
    (setter: React.SetStateAction<T>) => {
      setInternalValue((prev: T) => {
        const value = typeof setter === 'function' ? (setter as any)(prev) : setter;
        return value;
      });
    },
    [onChange],
  );

  return [internalValue, handleChange] as const;
};
