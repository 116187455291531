import WebViewer, { Core, WebViewerOptions, type WebViewerInstance } from '@pdftron/webviewer';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { APRYSE_LICENSE } from '@/lib/config';
import { convertBlobToBase64, getBlobFromAnnotation } from '@/lib/apryse';

type Mode = 'view' | 'edit' | 'sign' | 'review';

type VegaWebViewerProps = {
  filename?: string;
  downloadUrl: string;
  mode?: Mode;
  onSave?: (blob: Blob) => void;
  onChange?: () => void;
  signatures?: string[];
  onAddSignature?: (blob: Blob) => void;
  editorName?: string;
  extension: string;
};

export type VegaWebViewerRef = {
  download: () => void;
};

export const VegaWebViewer = forwardRef<VegaWebViewerRef, VegaWebViewerProps>((props, ref) => {
  const viewer = useRef<HTMLDivElement>(null);
  const webviewer = useRef<WebViewerInstance>();
  const [signatureTool, setSignatureTool] = useState<Core.Tools.SignatureCreateTool>();
  const [isSignatureToolReady, setIsSignatureToolReady] = useState(false);
  const [isOfficeDoc, setIsOfficeDoc] = useState(false);

  const getProperFilename = (originalFilename: string, isOfficeDocument: boolean) => {
    const baseFilename = originalFilename?.replace(/\.(pdf|docx?)$/, '') ?? 'Document';
    return isOfficeDocument ? `${baseFilename}.docx` : `${baseFilename}.pdf`;
  };

  useImperativeHandle(ref, () => ({
    download: () => {
      if (!webviewer.current) return;
      const properFilename = getProperFilename(props.filename ?? 'Document', isOfficeDoc);

      if (isOfficeDoc) {
        // For Office documents, use the office download method
        webviewer.current.Core.documentViewer
          .getDocument()
          .getFileData({
            downloadType: 'office',
          })
          .then((data) => {
            const arr = new Uint8Array(data);
            const blob = new Blob([arr], {
              type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = properFilename;
            a.click();
            URL.revokeObjectURL(url);
          });
      } else {
        // For PDFs, use the standard PDF download
        webviewer.current.UI.downloadPdf({
          filename: properFilename,
          includeAnnotations: true,
        });
      }
    },
  }));

  useEffect(() => {
    if (!viewer.current || !props.downloadUrl || webviewer.current) return;

    WebViewer(
      {
        path: '/webviewer',
        licenseKey: APRYSE_LICENSE ?? '',
        config: '/config.js',
        annotationUser: props.editorName ?? 'Guest',
        filename: props.filename ?? 'Document',
        ...getModeOption(props.mode ?? 'view'),
      },
      viewer.current,
    ).then(async (instance: WebViewerInstance) => {
      instance.UI.setLayoutMode(instance.UI.LayoutMode.Continuous);
      instance.Core.disableEmbeddedJavaScript();

      const { documentViewer, annotationManager, Actions } = instance.Core;
      (Actions.setCustomOnTriggeredHandler as any)(Actions.URI, () => { });

      documentViewer.addEventListener('documentLoaded', () => {
        const signatureTool = documentViewer.getTool(
          'AnnotationCreateSignature',
        ) as Core.Tools.SignatureCreateTool;

        const documentType = documentViewer.getDocument().getType();

        const isOffice = documentType.startsWith('office');
        setIsOfficeDoc(isOffice);

        setSignatureTool(signatureTool);
        documentViewer.setFitMode(documentViewer.FitMode.FitWidth);

        if (props.mode == 'sign' || props.mode == 'edit') {
          documentViewer.addEventListener('keyUp', () => {
            props.onChange?.();
            saveDocument();
          });

          annotationManager.addEventListener('annotationChanged', (_annotations, _action) => {
            props.onChange?.();
            saveDocument();
          });
        }
      });

      documentViewer.loadDocument(props.downloadUrl, {
        enableOfficeEditing: props.mode == 'edit' && props.extension === 'docx',
        filename: props.filename,
      });

      webviewer.current = instance;
    });
  }, [viewer.current, props.downloadUrl, props.mode, props.extension]);

  useEffect(() => {
    if (!webviewer.current) return;

    const documentViewer = webviewer.current.Core.documentViewer;

    if (props.mode === 'edit' && isOfficeDoc) {
      console.log('Enabling annotations');
      documentViewer.enableAnnotations();
    }
  }, [isOfficeDoc, props.mode]);

  useEffect(() => {
    if (!signatureTool || !webviewer.current) return;

    const documentViewer = webviewer.current.Core.documentViewer;

    const signatureReadyListener = async (annotation: Core.Annotations.Annotation) => {
      const blob = await getBlobFromAnnotation(annotation, documentViewer);
      if (blob) {
        props.onAddSignature?.(blob);
      }
    };

    signatureTool.addEventListener('signatureReady', signatureReadyListener);

    return () => {
      signatureTool?.removeEventListener('signatureReady', signatureReadyListener);
    };
  }, [signatureTool, props.onAddSignature]);

  const importSignatures = useCallback(async () => {
    if (!webviewer.current || !props.signatures?.length || !signatureTool) return;
    if (isSignatureToolReady) return;

    const getBlob = async (url: string) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const base64 = await convertBlobToBase64(blob);
        return base64;
      } catch (err) {
        console.error(err);
        return null;
      }
    };

    const signatureBase64s = await Promise.all(props.signatures?.map(getBlob));
    const nonNullBase64s = signatureBase64s.filter(Boolean) as string[];
    await signatureTool.importSignatures(nonNullBase64s);

    setIsSignatureToolReady(true);
  }, [props.signatures, isSignatureToolReady, signatureTool]);

  useEffect(() => {
    importSignatures();
  }, [importSignatures]);

  const saveDocument = useDebouncedCallback(async () => {
    if (!webviewer.current || !props.downloadUrl) return;
    if (props.mode == 'view' || props.mode == 'review') return;

    const doc = webviewer.current.Core.documentViewer.getDocument();
    if (!doc) return;

    try {
      let data;
      let mimeType;

      if (isOfficeDoc) {
        // For Office documents, use office downloadType
        data = await doc.getFileData({
          downloadType: 'office',
        });
        mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      } else {
        // For PDFs, include annotations via xfdfString
        const xfdfString = await webviewer.current.Core.annotationManager?.exportAnnotations();
        data = await doc.getFileData({
          xfdfString,
        });
        mimeType = 'application/pdf';
      }

      const arr = new Uint8Array(data);
      const blob = new Blob([arr], { type: mimeType });
      props.onSave?.(blob);
    } catch (error) {
      console.error('Error saving document:', error);
    }
  }, 3000);

  return <div ref={viewer} className='shadow-sm rounded-lg h-full' />;
},
);

VegaWebViewer.displayName = 'VegaWebViewer';


const getModeOption = (mode: Mode): Partial<WebViewerOptions> => {
  const disabledElements = [
    'menuButton',
    'menuOverlay',
    'viewControlsButton',
    'viewControlsOverlay',
    'officeEditorFileName',
    'contextMenuPopup',
    'toolbarGroup-Annotate',
    'toolbarGroup-Shapes',
  ];

  switch (mode) {
  case 'view':
    disabledElements.push(
      'toolbarGroup-Insert',
      'toolbarGroup-Forms',
      'toolbarGroup-Edit',
      'undoButton',
      'redoButton',
      'signatureToolsButton',
    );
    break;

  case 'sign':
    disabledElements.push(
      'toolbarGroup-Insert',
      'toolbarGroup-Edit',
    );
    break;

  case 'edit':
    disabledElements.push(
      'toolbarGroup-Forms',
      'signatureToolsButton',
    );
    break;

  case 'review':
    disabledElements.push(
      'toolbarGroup-Insert',
      'toolbarGroup-Forms',
      'undoButton',
      'redoButton',
      'signatureToolsButton',
    );
    break;

  default:
    break;
  }

  return {
    disabledElements,
    isReadOnly: mode === 'view' || mode === 'review',
  };
};
