import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import type { FirebaseApp, FirebaseOptions } from 'firebase/app';
import { getDatabase, type Database } from 'firebase/database';
import type { Auth } from 'firebase/auth';
import { Functions, getFunctions } from 'firebase/functions';
import { FirebaseStorage, getStorage } from 'firebase/storage';
import { Firestore, getFirestore } from 'firebase/firestore';

export let db: Database;
export let app: FirebaseApp;
export let auth: Auth;
export let functions: Functions;
export let storage: FirebaseStorage;
export let firestore: Firestore;

const config: FirebaseOptions = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
};

const useEmulator = import.meta.env.VITE_FIREBASE_USE_EMULATOR === 'true';

export const initializeFirebase = () => {
  if (!app) {
    app = initializeApp(config);
    auth = getAuth(app);
    db = getDatabase(app);
    functions = getFunctions(app);
    storage = getStorage(app);
    firestore = getFirestore(app);

    if (useEmulator) {
      connectAuthEmulator(auth, 'http://127.0.0.1:9099');
    }
  }
};
