import { cn, colorToLightness } from '@/lib/utils';
import { forwardRef, useMemo } from 'react';
import { Button, ButtonProps } from '../../../components/ui';
import { useStatuses } from '../hooks/use-statuses';

type StatusPillProps = ButtonProps & {
  statusId?: string;
  noBg?: boolean;
};

export const StatusPill = forwardRef<HTMLButtonElement, StatusPillProps>(
  ({ statusId, className, children, noBg = false, ...props }, ref) => {

    const { getStatus } = useStatuses('StatusPill');

    const status = useMemo(() => {
      return getStatus(statusId);
    }, [statusId, getStatus]);

    const style = useMemo(() => {
      const backgroundColor = status?.color ?? 'rgba(var(--primary) / 0.1)';
      const color =
        colorToLightness(backgroundColor) === 'black'
          ? 'rgba(var(--foreground) / 0.8)'
          : 'rgba(var(--background) / 0.8)';
      return {
        '--status-bg': backgroundColor,
        '--status-color': noBg ? backgroundColor : color,
      } as React.CSSProperties;
    }, [status?.color, noBg]);

    return (
      <Button asChild variant='mid' style={{ border: noBg ? 0 : 1 }} {...props} ref={ref}>
        <span
          style={style}
          className={cn(
            '!py-1 !px-4 !h-auto flex items-center justify-center capitalize rounded-lg cursor-pointer',
            'text-[var(--status-color)]',
            noBg ? 'bg-transparent' : 'bg-[var(--status-bg)]',
            className,
          )}
        >
          {status.label}
          {children}
        </span>
      </Button>
    );
  },
);

StatusPill.displayName = 'StatusPill';
