import { createContext, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { createStore, useStore } from 'zustand';

interface UIProps {
  isSideBarOpen: boolean;
}

interface UIState extends UIProps {
  toggleSideBar: () => void;
}

type UIStore = ReturnType<typeof createBearStore>;

const createBearStore = (initProps?: Partial<UIProps>) => {
  const DEFAULT_PROPS: UIProps = {
    isSideBarOpen: false,
  };
  return createStore<UIState>()((set) => ({
    ...DEFAULT_PROPS,
    ...initProps,
    toggleSideBar: () => set((state) => ({ isSideBarOpen: !state.isSideBarOpen })),
  }));
};

const UIContext = createContext<UIStore | null>(null);

type UIProviderProps = React.PropsWithChildren<Partial<UIProps>>;

export function UIProvider({ children, ...props }: UIProviderProps) {
  const { pathname } = useLocation();
  const storeRef = useRef<UIStore>();
  if (!storeRef.current) {
    storeRef.current = createBearStore(props);
  }

  // Close sidebar on route change
  useEffect(() => {
    storeRef.current?.setState({ isSideBarOpen: false });
  }, [pathname]);

  return <UIContext.Provider value={storeRef.current}>{children}</UIContext.Provider>;
}

const providerName = 'UI';

export function useUI<T = UIState>(
  consumerName: string,
  selector: (state: UIState) => T = (state: UIState) => state as T,
): T {
  const store = useContext(UIContext);
  if (!store) throw new Error(`${consumerName} must be used within a ${providerName}Provider`);
  return useStore(store, selector);
}
