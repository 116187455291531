import { useUser } from '@/providers/user';
import { off, onChildAdded, onValue, ref, Unsubscribe } from 'firebase/database';
import { useEffect, useState } from 'react';
import { useDatabase } from 'reactfire';

type CompanyRef = {
  id: string;
  name: string;
  role: any;
  country?: string;
};

export const useUserCompanies = (consumerName: string) => {
  const { user } = useUser(consumerName);
  const [companies, setCompanies] = useState<Record<string, CompanyRef>>({});
  const [loading, setLoading] = useState(true);

  const database = useDatabase();

  useEffect(() => {
    const userCompaniesRef = ref(database, `users/${user?.uid}/companies`);
    const handlers: Unsubscribe[] = [];

    const childAddedHandler = onChildAdded(
      userCompaniesRef,
      (userCompaniesSnapshot) => {
        const companyRef = ref(database, `companies/${userCompaniesSnapshot.key}`);
        const valueHandler = onValue(companyRef, (companysnapshot) => {
          if (!companysnapshot.exists()) {
            return setCompanies((prev) => {
              const { [userCompaniesSnapshot.key as string]: _, ...rest } = prev;
              return rest;
            });
          };

          setCompanies((prev) => {
            const company = companysnapshot.val() as CompanyRef;
            return {
              ...prev,
              [companysnapshot.key as string]: {
                id: company.id,
                name: company.name,
                role: userCompaniesSnapshot.val().role,
              }
            };
          });
          setLoading(false);
        });
        handlers.push(valueHandler);
      }
    );

    return () => {
      handlers.forEach((unsubscribe) => unsubscribe());
      off(userCompaniesRef, 'child_added', childAddedHandler);
    };
  }, [database, user?.uid]);

  return {
    companies: Object.values(companies),
    loading
  };
};