import { Doc, VegaFile } from '@/lib/definitions';
import { useUser } from '@/providers/user';
import { and, doc, DocumentData, or, orderBy, Query, query, where } from 'firebase/firestore';
import { useFirestoreCollectionData, useFirestoreDocData } from 'reactfire';
import { getFileCollection } from '../actions/files';
import { useCompany } from '../providers/company';

export const useFiles = (consumerName: string, parentId = '~') => {
  const { user } = useUser(consumerName);
  const { activeCompany: company } = useCompany(consumerName);

  const hasAccess = where('accessBy', 'array-contains', user.uid);
  const isOwner = where('createdBy', '==', user.uid);
  // TODO: handle recursive access to parent folders
  const isPublic = or(where('accessBy', '==', []), where('accessBy', '==', null));

  const filesQuery = query(
    getFileCollection(company.id),
    and(
      where('parent', '==', parentId),
      where('deletedAt', '==', null),
      or(hasAccess, isOwner, isPublic),
    ),
    orderBy('name', 'asc'),
    orderBy('createdAt', 'desc'),
  ) as Query<VegaFile, DocumentData>;

  return useFirestoreCollectionData<VegaFile>(filesQuery, { idField: 'id' });
};

export const useFile = (consumerName: string, fileId: string) => {
  const { activeCompany: company } = useCompany(consumerName);
  const fileReference = doc(getFileCollection(company.id), fileId);

  return useFirestoreDocData<Doc>(fileReference as any, { idField: 'id' });
};