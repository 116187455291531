import { createBrowserRouter } from 'react-router-dom';
import { lazyImport } from '@/lib/utils';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

// MISC
const { NotFound } = lazyImport(() => import('@/features/misc/not-found'), 'NotFound');

export const router = createBrowserRouter([
  ...publicRoutes,
  ...protectedRoutes,
  { path: '*', element: <NotFound /> },
]);
