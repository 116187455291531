import { cn } from '@/lib/utils';
import vegaPng from '@/assets/luv2.png';
import { useVendor } from '@/features/company/hooks/use-vendor';
import { cva, type VariantProps } from 'class-variance-authority';

const logoVariants = cva(
  'w-auto',
  {
    variants: {
      size: {
        default: 'h-12',
        sm: 'h-10',
        md: 'h-12',
        lg: 'h-16',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  }
);

type LogoProps = {
  className?: string;
} & VariantProps<typeof logoVariants>;

export const VegaIcon = ({ className, size }: LogoProps) => (
  <span className={cn('justify-center items-center', className)}>
    <img src={vegaPng} className={cn(logoVariants({ size }))} alt='LawyerUp' />
  </span>
);

export const BrandIcon = ({ className, size }: LogoProps) => {
  const [vendor, loading] = useVendor();

  if (loading) {
    return null;
  }

  return (
    <span className={cn('justify-center items-center', className)}>
      {vendor?.branding.logoUrl ? (
        <img
          src={vendor.branding.logoUrl}
          alt={vendor.name}
          className={cn(logoVariants({ size }))}
        />
      ) : (
        <img src={vegaPng} className={cn(logoVariants({ size }))} alt='LawyerUp' />
      )}
    </span>
  );
};
